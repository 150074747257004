import * as React from 'react';
import {RefObject, useEffect, useState} from 'react';
import {Button, InputGroup} from 'reactstrap';
import './PlaylistIndex.css';
import PlaylistData from "../playlist/data/PlaylistData";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faSearch} from "@fortawesome/free-solid-svg-icons";
import {DelayedFilterInput} from "../common/DelayedFilterInput";
import {CyFilterHeader} from "./CyFilterHeader";

interface Props {
    performSearch: (searchTerm: string) => void
    OrderByBuilder: (orderByProps: { title: string, field: keyof PlaylistData, dataCy? : string }) => JSX.Element
    visible: boolean
    currentCrewIdentifier : string | undefined
}

export function FilterHeader(props: Props) {
    const [isFilterActive, setIsFilterActive] = useState<boolean>(false);

    const searchfieldRef: RefObject<HTMLInputElement> = React.createRef();

    useEffect(() => {
        setIsFilterActive(false)
    }, [props.currentCrewIdentifier]);

    return (
        <InputGroup className={props.visible ? "" : "d-none"}>
            <Button
                color="primary"
                onClick={() => setIsFilterActive(p => {
                    props.performSearch("")
                    return !p
                })}
                className="me-2"
                active={isFilterActive}
                data-cy={CyFilterHeader.ButtonToggleFilter}
            >
                <FontAwesomeIcon icon={faSearch}/>
            </Button>
            {isFilterActive ?
                <DelayedFilterInput
                    onSearchTermChanged={props.performSearch}
                    autoFocus={true}
                    inputRef={searchfieldRef}
                    placeholder="Playlist suchen"
                />
                :
                <props.OrderByBuilder title="Playlist" field="titleLowercase" dataCy={CyFilterHeader.ButtonOrderByPlaylistTitle}/>
            }
        </InputGroup>
    );
}
