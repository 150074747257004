import * as React from 'react';
import {useEffect, useState} from 'react';
import PlaylistData from "../playlist/data/PlaylistData";
import './SongsheetCarousel.css';
import PlaylistItemData from "../playlist/data/PlaylistItemData";
import {useHistory} from "react-router-dom";
import PlaylistBanner from "./PlaylistBanner";
import useToggleValueWithLocalStoragePersistence from "../common/hooks/useToggleValueWithLocalStoragePersistence";
import Auth from "../auth/Auth";
import {Permission} from "../auth/Permission";
import CarouselSettingsSidebar from "./CarouselSettingsSidebar";
import CarouselPlaylistSidebar from "./CarouselPlaylistSidebar";
import {CarouselToolbar} from "./CarouselToolbar";
import {BeamerBroadcastChannelSender} from "../beamer/BeamerBroadcastChannelSender";
import {PlaylistRoutePaths} from "../playlist/PlaylistRoutePaths";
import {useMediaQuery} from "../common/hooks/useMediaQuery";
import {Swiper, SwiperSlide} from "swiper/react";
import 'swiper/css';
import 'swiper/css/zoom';
import 'swiper/css/pagination';
import {Swiper as SwiperClass} from "swiper/types";
import {Zoom} from "swiper";
import {SongshipSwiperSlide} from "./SongshipSwiperSlide";
import {SongsheetBeamerControl} from "../beamer/SongsheetBeamerControl";
import {useStateWithLocalStoragePersistenceAndDefault} from "../common/hooks/useStateWithLocalStoragePersistenceAndDefault";
import {VirtualSlide} from "./slide/VirtualSlide";
import {VirtualSlideFactory} from "./slide/VirtualSlideFactory";
import {ItemId} from "../playlist/data/PlaylistTypes";
import _ from "lodash";
import {VirtualInvalidSlide} from "./slide/VirtualInvalidSlide";
import {VirtualTextOnlySlide} from "./slide/VirtualTextOnlySlide";
import {VirtualSongsheetSlide} from "./slide/VirtualSongsheetSlide";
import {InvalidSlide} from "./slide/InvalidSlide";
import {TextOnlySongSlide} from "./slide/TextOnlySongSlide";
import {SongsheetSlide} from "./slide/SongsheetSlide";
import SwitchUtils from "../common/SwitchUtils";
import {useHotkeys} from "react-hotkeys-hook";
import {bigScreenThreshold} from "../App";
import LocalStorageKey from "../localStoragePersistence/common/LocalStorageKey";
import {RoutePaths} from "../RoutePaths";

interface Props {
    playlistIdentifier: string
    playlistData: PlaylistData
    selectedItem: PlaylistItemData | undefined
    setSelectedItem: (item: PlaylistItemData | undefined) => void
    auth: Auth
}

const channel = new BeamerBroadcastChannelSender();

export function SongsheetCarousel(props: Props) {

    useHotkeys('escape', () => {
        const url = RoutePaths.toPlaylist(props.playlistIdentifier);
        history.push(url);
    })
    useHotkeys('left', () => previousPageInCarousel())
    useHotkeys('right', () => nextPageInCarousel())


    const isBigScreen = useMediaQuery(`(min-width: ${bigScreenThreshold})`);

    const [showToolbar, setShowToolbar] = useState(true);
    const [showSettingsSidebar, setShowSettingsSidebar] = useState(false);
    const [showPlaylistSidebar, setShowPlaylistSidebar] = useToggleValueWithLocalStoragePersistence(new LocalStorageKey("SongsheetCarousel", "showPlaylistSidebar"), false);
    const [showMainContent, setShowMainContent] = useState(true);
    const [showBanner, setShowBanner] = useToggleValueWithLocalStoragePersistence(new LocalStorageKey("SongsheetCarousel", "showBanner"), true);
    const [isBeamerModeActiveSetting, setIsBeamerModeActiveSetting] = useToggleValueWithLocalStoragePersistence(new LocalStorageKey("SongsheetCarousel", "isBeamerModeActive"), false);
    const [activePageInCarousel, setActivePageInCarouselInternal] = useState<number>(0);
    const [swiper, setSwiper] = useState<SwiperClass | undefined>(undefined);
    const [activeLyricsPart, setActiveLyricsPart] = useState<number | undefined>(undefined);
    const [amountOfPagesInSongsheetCarousel, setAmountOfPagesInSongsheetCarousel] = useStateWithLocalStoragePersistenceAndDefault<number>(new LocalStorageKey("SongsheetCarousel", "amountOfPagesInSongsheetCarousel"), 1);

    const [virtualSlides, setVirtualSlides] = useState<VirtualSlide[]>(buildVirtualSlides(props.playlistData.getItems()));

    function buildVirtualSlides(items: readonly PlaylistItemData[]): VirtualSlide[] {
        return isBeamerModeActive() ? VirtualSlideFactory.buildLyricsSlides(items) : VirtualSlideFactory.buildSongsheetSlides(items)
    }

    useEffect(() => {
        setVirtualSlides(buildVirtualSlides(props.playlistData.getItems()))
    }, [props.playlistData, isBeamerModeActiveSetting]);

    //used to be able to switch between lyrics and songsheet mode
    useEffect(() => {
        setSelectedItemViaActivePageInCarousel(props.selectedItem)
    }, [virtualSlides]);

    function isBeamerModeActive(): boolean {
        if (props.auth.hasPermissions([Permission.songsheetsRead])) {
            return isBeamerModeActiveSetting;
        } else {
            return true
        }
    }

    useEffect(() => {
        //we define de speed explicitly because the default value sometimes is undefined while loading
        swiper && swiper.slideTo(activePageInCarousel, getSpeed());
    }, [activePageInCarousel, swiper]); // eslint-disable-line react-hooks/exhaustive-deps


    const history = useHistory();

    useEffect(() => {
        setShowMainContent(isBigScreen || (!showPlaylistSidebar && !showSettingsSidebar));
        setShowToolbar(isBigScreen || (!showPlaylistSidebar && !showSettingsSidebar));
    }, [isBigScreen, setShowMainContent, showPlaylistSidebar, showSettingsSidebar]);

    function getSlideIndexOfItemId(itemId: ItemId): number | undefined {
        const index = _.findIndex(virtualSlides, (item) => item.playlistItemData.itemId === itemId)
        return index === -1 ? undefined : index;
    }

    function getPlaylistEntryBySlideIndex(slideIndex: number): PlaylistItemData | undefined {
        return _.get(virtualSlides, slideIndex)?.playlistItemData
    }

    function setIsBeamerModeActive(active: boolean) {
        setIsBeamerModeActiveSetting(active);
        //because the texts are scrollable, we need to disable the swipe gesture in beamer mode (swiper can not handle this)
        if (swiper) swiper.allowTouchMove = !active;
    }

    //only at the beginning, because it would always re-select the first page if the carousel swipes to the second page of a song
    useEffect(() => {
        setActivePageInCarousel(getSlideIndexOfItemId(props.selectedItem?.itemId || 0) || 0);
    }, []); // eslint-disable-line react-hooks/exhaustive-deps

    function setActivePageInCarousel(newIndex: number) {
        if (newIndex >= 0 && newIndex < virtualSlides.length) {
            setActiveLyricsPart(undefined);
            setActivePageInCarouselInternal(newIndex);
        }
    }

    function previousPageInCarousel() {
        setActivePageInCarousel(activePageInCarousel - 1);
    }

    function nextPageInCarousel() {
        setActivePageInCarousel(activePageInCarousel + 1);
    }

    function emptyLyricsScreen() {
        setActiveLyricsPart(undefined);
        channel.emptyScreen();
    }

    // we do changes to the selectedItem via changing the active page to not end in conflicts
    function setSelectedItemViaActivePageInCarousel(item: PlaylistItemData | undefined) {
        setActivePageInCarousel(getSlideIndexOfItemId(item?.itemId || 0) || 0);
    }

    useEffect(() => {
        props.setSelectedItem(getPlaylistEntryBySlideIndex(activePageInCarousel));
    }, [props.playlistData, activePageInCarousel]); // eslint-disable-line react-hooks/exhaustive-deps


    function generateSwiper() {
        return <Swiper
            style={{flex: "1"}}
            speed={getSpeed()}
            modules={[Zoom]}
            slidesPerView={isBeamerModeActive() ? 1 : amountOfPagesInSongsheetCarousel}
            zoom={true}
            allowTouchMove={!isBeamerModeActive()} //initially, but also need to set on state const "swiper" because it is not updated by react
            onSlideChange={(swiper) => setActivePageInCarousel(swiper.activeIndex)}
            onSwiper={(s: SwiperClass) => setSwiper(s)}
        >
            {virtualSlides.map(v => {
                    switch (v.tag) {
                        case "VirtualInvalidSlide" :
                            return <SwiperSlide key={v.buildKey()}>
                                <InvalidSlide/>
                            </SwiperSlide>
                        case "VirtualTextOnlySlide" :
                            return <SwiperSlide key={v.buildKey()}>
                                <TextOnlySongSlide/>
                            </SwiperSlide>
                        case "VirtualSongsheetSlide" :
                            return <SongshipSwiperSlide key={v.buildKey()}>
                                <SongsheetSlide pictureReference={v.pictureReference}/>
                            </SongshipSwiperSlide>
                        case "VirtualLyricsSlide" :
                            return <SwiperSlide key={v.buildKey()}>
                                <SongsheetBeamerControl
                                    tocEntry={v.toCEntry}
                                    channel={channel}
                                    activeLyricsPart={activeLyricsPart}
                                    setActiveLyricsPart={setActiveLyricsPart}
                                    key={v.buildKey()}
                                />
                            </SwiperSlide>
                        default:
                            return SwitchUtils.throwUnsupportedValue(v);
                    }
                }
            )}
        </Swiper>
    }

    function runningInCypress() {
        // @ts-ignore
        return window.Cypress;
    }

    function openBeamerWindow() {
        window.open(PlaylistRoutePaths.toBeamerView(props.playlistIdentifier), "beamer", "fullscreen=yes");
    }

    function getSpeed() {
        return runningInCypress() ? 0 : 300;
    }

    return (
        /**
         * idea of the wrapper (see layout.png): http://jsfiddle.net/ch7n6/867/
         */
        <div className="wrapper">
            {showBanner && <PlaylistBanner {...props} />}
            <div style={{flex: "1 1 auto", display: "flex", overflowY: "auto"}}>
                {showMainContent && generateSwiper()}
                {showSettingsSidebar && <div className="sidebar">

                    <CarouselSettingsSidebar setShowBanner={setShowBanner} showBanner={showBanner}
                                             beamerBroadcastChannelSender={channel} auth={props.auth} isBeamerModeActive={isBeamerModeActive()}
                                             setIsBeamerModeActive={setIsBeamerModeActive} openBeamerWindow={openBeamerWindow}
                                             amountOfPagesInSongsheetCarousel={amountOfPagesInSongsheetCarousel} setAmountOfPagesInSongsheetCarousel={setAmountOfPagesInSongsheetCarousel}
                                             closeSettingsSidebar={() => setShowSettingsSidebar(false)}/>
                </div>}
                {showPlaylistSidebar && <div className="sidebar">
                    <CarouselPlaylistSidebar
                        playlistData={props.playlistData}
                        selectedItem={props.selectedItem}
                        setSelectedItem={setSelectedItemViaActivePageInCarousel}
                        closeSidebar={() => setShowPlaylistSidebar(false)}
                        isBigScreen={isBigScreen}/>
                </div>}
                {showToolbar && <div className="toolbar">
                    <CarouselToolbar playlistIdentifier={props.playlistIdentifier} showPlaylistSidebar={showPlaylistSidebar} showSettingsSidebar={showSettingsSidebar}
                                     setShowPlaylistSidebar={setShowPlaylistSidebar} setShowSettingsSidebar={setShowSettingsSidebar} isBeamerModeActive={isBeamerModeActive()}
                                     setIsBeamerModeActive={setIsBeamerModeActive}
                                     previousPageInCarousel={previousPageInCarousel} nextPageInCarousel={nextPageInCarousel} emptyLyricsScreen={emptyLyricsScreen}
                                     openBeamerWindow={openBeamerWindow}/>
                </div>}
            </div>
        </div>
    );
}