import * as React from 'react';
import {useEffect, useRef, useState} from 'react';
import {Alert, Button, Card, CardHeader} from 'reactstrap';
import {TocEntryDTO} from "../dto/v1/toc/TocEntryDTO";
import ScanQrCodeDialog from "./ScanQrCodeDialog";
import {TocIdDTO} from "../dto/v3/toc/TocIdDTO";
import useToggleValueWithLocalStoragePersistence from "../common/hooks/useToggleValueWithLocalStoragePersistence";
import './PlaylistIndex.css';
import {faBarsStaggered, faExclamationTriangle} from "@fortawesome/free-solid-svg-icons";
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome';
import {PlaylistIndexLoader} from "./PlaylistIndexLoader";
import PlaylistData from "../playlist/data/PlaylistData";
import {useStateWithLocalStoragePersistenceAndDefault} from "../common/hooks/useStateWithLocalStoragePersistenceAndDefault";
import {CyPlaylistIndex} from "./CyPlaylistIndex";
import {PlaylistIndexTable} from "./PlaylistIndexTable";
import {PlaylistIndexToolbar} from "./PlaylistIndexToolbar";
import {PlaylistIndexAdvancedMenu} from "./PlaylistIndexAdvancedMenu";
import {Link} from "react-router-dom";
import {PlaylistIndexSearchEngine} from "./PlaylistIndexSearchEngine";
import {LazyCollapse} from "../common/LazyCollapse";
import {CrewTitle} from "./CrewTitle";
import LocalStorageKey from "../localStoragePersistence/common/LocalStorageKey";
import {PlaylistIndexTexts} from "./PlaylistIndexTexts";
import {defaultAmountOfEntriesInPlaylistIndex} from "./DefaultAmountOfEntriesInPlaylistIndex";

interface Props {
    lookupSong: (tocId: TocIdDTO) => TocEntryDTO | undefined
    currentCrewIdentifier: string | undefined
    setCurrentCrewIdentifier: (i: string | undefined) => void
}

const playlistIndexLoader = new PlaylistIndexLoader();

function PlaylistIndex(props: Props) {

    const [orderByField, setOrderByField] = useStateWithLocalStoragePersistenceAndDefault<keyof PlaylistData>(new LocalStorageKey("PlaylistIndex", "orderByField"), "updatedAt");
    const [orderByAscOrDesc, setOrderByAscOrDesc] = useStateWithLocalStoragePersistenceAndDefault<"asc" | "desc">(new LocalStorageKey("PlaylistIndex", "orderByAscOrDesc"), "desc");

    const [playlistIndexData, setPlaylistIndexData] = useState<ReadonlyArray<PlaylistData>>([]);
    const [playlistIndexSearchEngine, setPlaylistIndexSearchEngine] = useState<PlaylistIndexSearchEngine>();

    const [showDetailsInPlaylistSummary, setShowDetailsInPlaylistSummary] = useToggleValueWithLocalStoragePersistence(new LocalStorageKey("PlaylistIndex", "showDetailsInPlaylistSummary"), true);
    const [scanQrCodeDialogActive, setScanQrCodeDialogActive]: [boolean, ((value: (((prevState: boolean) => boolean) | boolean)) => void)] = useState<boolean>(false);

    const [playlistAdvancedMenuOpen, setPlaylistAdvancedMenuOpen] = useState<boolean>(false);

    const [amountOfEntriesInPlaylistIndex, setAmountOfEntriesInPlaylistIndex] = useStateWithLocalStoragePersistenceAndDefault<number>(new LocalStorageKey("PlaylistIndex", "amountOfEntriesInPlaylistIndex"), defaultAmountOfEntriesInPlaylistIndex);

    const playlistIndexTableHeaderRef: React.RefObject<HTMLDivElement> = useRef<HTMLDivElement>(null);

    const [selectionList, setSelectionList] = useState<string[]>([]);

    useEffect(() => {
        performIndexRefresh()
    }, [props.currentCrewIdentifier]);  // eslint-disable-line react-hooks/exhaustive-deps

    useEffect(() => {
        setSelectionList([])
    }, [playlistIndexData, amountOfEntriesInPlaylistIndex]);

    function performIndexRefresh(): void {
        const orderedPlaylistIndex = playlistIndexLoader.orderBy(playlistIndexLoader.loadAll(props.lookupSong, props.currentCrewIdentifier), orderByField, orderByAscOrDesc);
        setPlaylistIndexSearchEngine(new PlaylistIndexSearchEngine(orderedPlaylistIndex))
        setPlaylistIndexData(orderedPlaylistIndex)
    }

    function changeOrderBy(newField: keyof PlaylistData) {
        const newAscOrDesc = newField !== orderByField ? "asc" : (orderByAscOrDesc === "asc" ? "desc" : "asc");
        const orderedPlaylistIndex = playlistIndexLoader.orderBy(playlistIndexData, newField, newAscOrDesc);
        setPlaylistIndexData(orderedPlaylistIndex)
        setOrderByField(newField);
        setOrderByAscOrDesc(newAscOrDesc)
    }

    function performSearch(searchTerm: string) {
        const results = playlistIndexSearchEngine ? playlistIndexSearchEngine.search(searchTerm) : playlistIndexData;
        setPlaylistIndexData(results)
        playlistIndexTableHeaderRef.current?.scrollIntoView()
    }

    return (
        <Card className="mt-3">
            <CardHeader className="pb-0" style={{backgroundColor: "#f8f9fa", borderBottom: "none"}}>
                <h2
                    className={"light-header"}
                    data-cy={CyPlaylistIndex.PlaylistPageHeader}
                >
                    <FontAwesomeIcon icon={faBarsStaggered}/> {PlaylistIndexTexts.PageHeaderPrefix}<CrewTitle currentCrewIdentifier={props.currentCrewIdentifier}/>
                </h2>
                <PlaylistIndexToolbar
                    performIndexRefresh={performIndexRefresh}
                    playlistIndexData={playlistIndexData}
                    scanQrCodeDialogActive={scanQrCodeDialogActive}
                    setScanQrCodeDialogActive={setScanQrCodeDialogActive}
                    showDetailsInPlaylistSummary={showDetailsInPlaylistSummary}
                    setShowDetailsInPlaylistSummary={setShowDetailsInPlaylistSummary}
                    playlistAdvancedMenuOpen={playlistAdvancedMenuOpen}
                    setPlaylistAdvancedMenuOpen={setPlaylistAdvancedMenuOpen}
                    currentCrewIdentifier={props.currentCrewIdentifier}
                />
                <LazyCollapse isOpen={playlistAdvancedMenuOpen}>
                    <PlaylistIndexAdvancedMenu
                        changeOrderBy={changeOrderBy}
                        currentOrder={orderByAscOrDesc}
                        currentField={orderByField}
                        showDetailsInPlaylistSummary={showDetailsInPlaylistSummary}
                        setShowDetailsInPlaylistSummary={setShowDetailsInPlaylistSummary}
                        amountOfEntriesInPlaylistIndex={amountOfEntriesInPlaylistIndex}
                        setAmountOfEntriesInPlaylistIndex={setAmountOfEntriesInPlaylistIndex}
                    />
                </LazyCollapse>
                <LazyCollapse isOpen={scanQrCodeDialogActive}>
                    <ScanQrCodeDialog/>
                </LazyCollapse>
            </CardHeader>
            <PlaylistIndexTable
                changeOrderBy={changeOrderBy}
                playlistIndexData={playlistIndexData.slice(0, amountOfEntriesInPlaylistIndex)}
                orderByAscOrDesc={orderByAscOrDesc}
                orderByField={orderByField}
                showDetailsInPlaylistSummary={showDetailsInPlaylistSummary}
                performSearch={performSearch}
                playlistIndexTableHeaderRef={playlistIndexTableHeaderRef}
                selectionList={selectionList}
                setSelectionList={setSelectionList}
                currentCrewIdentifier={props.currentCrewIdentifier}
                setCurrentCrewIdentifier={props.setCurrentCrewIdentifier}
                performIndexRefresh={performIndexRefresh}
            />
            {amountOfEntriesInPlaylistIndex < playlistIndexData.length && <Alert color="warning" className="mt-2 mb-0">
                <FontAwesomeIcon icon={faExclamationTriangle}/> Es werden nur die ersten {amountOfEntriesInPlaylistIndex} Playlisten angezeigt.
                <Button
                    color={"link"}
                    to={""}
                    tag={Link}
                    onClick={() => setAmountOfEntriesInPlaylistIndex(1000)}
                    data-cy={CyPlaylistIndex.ShowAllPlaylistsLink}
                >Alle anzeigen</Button>
            </Alert>}
        </Card>
    );
}

export default PlaylistIndex;
