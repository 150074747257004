import PlaylistItemData from "../../playlist/data/PlaylistItemData";
import {TocEntryDTO} from "../../dto/v1/toc/TocEntryDTO";


export class VirtualLyricsSlide {
    public readonly tag = "VirtualLyricsSlide"

    public readonly playlistItemData: PlaylistItemData;
    public readonly toCEntry: TocEntryDTO;

    constructor(item: PlaylistItemData, toCEntry : TocEntryDTO) {
        this.playlistItemData = item;
        this.toCEntry = toCEntry;
    }

    buildKey(): string {
        return `${this.playlistItemData.itemId}`
    }

}