import * as React from 'react';
import _ from "lodash";
import {Card, CardBody, ListGroup, ListGroupItem} from "reactstrap";
import {CyCarousel} from "../carousel/CyCarousel";
import {BeamerBroadcastChannelSender} from "./BeamerBroadcastChannelSender";
import {TocEntryDTO} from "../dto/v1/toc/TocEntryDTO";

interface Props {
    tocEntry: TocEntryDTO
    channel: BeamerBroadcastChannelSender
    activeLyricsPart: number | undefined
    setActiveLyricsPart: (activeLyricsPart: number | undefined) => void
}

export function SongsheetBeamerControl(props: Props) {

    function postMessage(newActiveIndex: number, message: string) {
        props.setActiveLyricsPart(newActiveIndex);
        props.channel.sendText(message);
    }

    function generateItems(songsheet: string): React.ReactNode {
        return _.replace(songsheet, /\r\n/g, "\n").split( "\n\n").map((verseOrRefrain: string, index: number) => {
            return (
                <ListGroupItem tag="button" color="link" action onClick={() => postMessage(index, verseOrRefrain)} key={index}
                               data-cy={CyCarousel.LyricsItem}
                               active={props.activeLyricsPart === index}>
                    {verseOrRefrain}
                </ListGroupItem>
            )
        })
    }

    function generateNoLyricsInfo(): React.ReactNode {
        return <ListGroupItem>
            Zu diesem Lied sind keine Liedtexte vorhanden.
        </ListGroupItem>
    }

    return (
        <div className="lyrics">
            <Card style={{/*fontFamily: "'Courier New', monospace",*/ whiteSpace: "pre-line"}}>
                <CardBody>
                    <ListGroup flush>
                        {props.tocEntry.lyrics ? generateItems(props.tocEntry.lyrics) : generateNoLyricsInfo()}
                    </ListGroup>
                </CardBody>
            </Card>

        </div>
    );
}