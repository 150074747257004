import _ from "lodash";
import PlaylistData from "./data/PlaylistData";
import PlaylistItemData from "./data/PlaylistItemData";
import ArrayUtils from "../common/ArrayUtils";
import {ItemId} from "./data/PlaylistTypes";
import {TocEntryDTO} from "../dto/v1/toc/TocEntryDTO";
import {TocIdDTO} from "../dto/v3/toc/TocIdDTO";
import {v4 as uuidv4} from "uuid";

class PlaylistManipulationUtils {

    public static maxOfIds(items: ReadonlyArray<PlaylistItemData>): number {
        return Math.max(...items.map(v => v.itemId), 0);
    }

    private static p(itemId: number) {
        return (li: PlaylistItemData) => li.itemId === itemId;
    }

    public static insertAfter(data: PlaylistData, itemToBeInserted: PlaylistItemData, itemId?: number): PlaylistData {
        const items = (itemId === undefined) ?
            _.concat(data.getItems(), itemToBeInserted) :
            ArrayUtils.insertAfter(data.getItems(), PlaylistManipulationUtils.p(itemId), itemToBeInserted);
        return data.copyWithItems(items);
    }

    public static remove(data: PlaylistData, itemId: ItemId): PlaylistData {
        const predicate = (v: PlaylistItemData) => v.itemId === itemId;
        const items = ArrayUtils.remove(data.getItems(), predicate);
        return data.copyWithItems(items);
    }

    public static up(data: PlaylistData, itemId: ItemId): PlaylistData {
        const items = ArrayUtils.up(data.getItems(), PlaylistManipulationUtils.p(itemId));
        return data.copyWithItems(items);
    }

    public static down(data: PlaylistData, itemId: ItemId): PlaylistData {
        const items = ArrayUtils.down(data.getItems(), PlaylistManipulationUtils.p(itemId));
        return data.copyWithItems(items);
    }

    public static findItemByItemIdParam(data: PlaylistData, itemIdParam: string): PlaylistItemData | undefined {
        if (itemIdParam !== undefined) {
            const itemIdParamNumber: number = +itemIdParam;
            const selectedItem = data.getItems().find(i => i.itemId === itemIdParamNumber);
            if (selectedItem === undefined) console.warn("Could not find itemIdParam=" + itemIdParam + " in playlist.", data.getItems());
            return selectedItem
        } else {
            return undefined;
        }
    }

    public static createItemToInsert(items: ReadonlyArray<PlaylistItemData>, lookupSong: (tocId: TocIdDTO) => TocEntryDTO | undefined, tocId: TocIdDTO) {
        const itemIdOfNewItem = PlaylistManipulationUtils.maxOfIds(items) + 1;
        return new PlaylistItemData(itemIdOfNewItem, tocId, lookupSong(tocId));
    }

    public static buildItemsFromTocIdArray(lookupSong: (tocId: TocIdDTO) => TocEntryDTO | undefined, tocIdArray: ReadonlyArray<TocIdDTO>): ReadonlyArray<PlaylistItemData> {
        return tocIdArray.map((tocId, index) => new PlaylistItemData(index + 1, tocId, lookupSong(tocId)))
    }

    public static removeInvalidItemsWithNoCEntry(data: PlaylistData): PlaylistData {
        const onlyValidEntries = data.getItems().filter(p => p.toCEntry)
        return data.copyWithItems(onlyValidEntries);
    }

    public static hasInvalidItemsWithNoCEntry(data: PlaylistData): boolean {
        return data.getItems().filter(p => p.toCEntry === undefined).length > 0;
    }

    public static readonly DuplicatorPostfix: string = " (Kopie)";


    /**
     * @param existing
     * @param belongsToCrew is optional, because we can copy a playlist from a crew to the personal playlist index
     */
    public static copyPlaylistFromExistingToCrew(
        existing: PlaylistData,
        belongsToCrew: string | undefined,
    ) {
        const adaptedTitle = existing.belongsToCrew === belongsToCrew ?
            existing.title + PlaylistManipulationUtils.DuplicatorPostfix
            : existing.title;
        return new PlaylistData(
            uuidv4(),
            adaptedTitle,
            belongsToCrew,
            existing.getTags(),
            existing.getItems(),
            new Date(),
            new Date(),
            0,
            true,
            false,
            false
        );
    }

}

export default PlaylistManipulationUtils;
