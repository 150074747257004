import * as React from 'react';
import {useEffect, useRef} from 'react';
import {Badge} from "reactstrap";
import PlaylistData from "../data/PlaylistData";
import PlaylistItemData from "../data/PlaylistItemData";

interface Props {
    playlistData: PlaylistData;
    selectedItem: PlaylistItemData | undefined;
}

export default function PlaylistBanner(props: Props) {


    const selectedItemBatchRef: React.MutableRefObject<HTMLElement | null> = useRef(null);

    useEffect(() => {
        selectedItemBatchRef.current?.scrollIntoView(true);
    }, [props.selectedItem]);

    return (
        <div style={{width: "100%", overflowX: "hidden", whiteSpace: "nowrap"}}>
            {props.playlistData.getItems().map(playlistEntry => {
                const active = playlistEntry.itemId === props.selectedItem?.itemId;
                return <Badge pill className="me-1" color={active ? "warning" : "secondary"}
                              innerRef={el => active ? selectedItemBatchRef.current = el : null}
                              key={playlistEntry.itemId}>{playlistEntry.toCEntry ? playlistEntry.toCEntry.title : "Ungültiger Eintrag"}</Badge>
            })}
        </div>

    );

}