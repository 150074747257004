export const CyCarousel = {
    LyricsItem: "LyricsItem",
    NextItem: "NextItem",
    PreviousItem: "PreviousItem",
    PlaylistSidebarButton: "PlaylistSidebarButton",
    SendEmptyScreenCommandButton: "SendEmptyScreenCommandButton",
    SettingsSidebarButton: "SettingsSidebarButton",
    IncreaseFontButton: "IncreaseFontButton",
    DecreaseFontButton: "DecreaseFontButton"
} as const;
