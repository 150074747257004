import * as React from 'react';
import {Button, ButtonGroup} from "reactstrap";
import './SongsheetCarousel.css';
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faArrowLeft, faArrowRight, faBookOpen, faCog, faEraser, faTimes, faVideo} from "@fortawesome/free-solid-svg-icons";
import {Link} from "react-router-dom";
import {CyCarousel} from "./CyCarousel";
import {RoutePaths} from "../RoutePaths";

interface Props {
    playlistIdentifier: string
    showPlaylistSidebar: boolean
    setShowPlaylistSidebar: (visible: boolean) => void
    showSettingsSidebar: boolean
    setShowSettingsSidebar: (visible: boolean) => void
    isBeamerModeActive: boolean
    setIsBeamerModeActive: (active: boolean) => void
    previousPageInCarousel: () => void
    nextPageInCarousel: () => void
    emptyLyricsScreen: () => void
    openBeamerWindow: () => void
}

export function CarouselToolbar(props: Props) {

    function togglePlaylistSidebar() {
        props.setShowSettingsSidebar(false);
        const newState = !props.showPlaylistSidebar
        props.setShowPlaylistSidebar(newState);
    }

    function toggleMenuSidebar() {
        props.setShowPlaylistSidebar(false);
        const newState = !props.showSettingsSidebar
        props.setShowSettingsSidebar(newState);
    }

    return (
        <>
            <ButtonGroup vertical>
                <Button tag={Link} to={RoutePaths.toPlaylist(props.playlistIdentifier)} outline size="lg">
                    <FontAwesomeIcon icon={faTimes}/>
                </Button>
                <Button onClick={e => togglePlaylistSidebar()} active={props.showPlaylistSidebar} outline size="lg" data-cy={CyCarousel.PlaylistSidebarButton}>
                    <FontAwesomeIcon icon={faBookOpen}/>
                </Button>
                <Button onClick={e => toggleMenuSidebar()} active={props.showSettingsSidebar} outline size="lg" data-cy={CyCarousel.SettingsSidebarButton}>
                    <FontAwesomeIcon icon={faCog}/>
                </Button>
            </ButtonGroup>

            <ButtonGroup vertical>
                {props.isBeamerModeActive && <>
                    <Button onClick={() => props.openBeamerWindow()} outline size="lg">
                        <FontAwesomeIcon icon={faVideo}/>
                    </Button>
                    <Button onClick={e => props.emptyLyricsScreen()} outline size="lg" data-cy={CyCarousel.SendEmptyScreenCommandButton}>
                        <FontAwesomeIcon icon={faEraser}/>
                    </Button>
                </>}
            </ButtonGroup>

            <ButtonGroup vertical>
                <Button onClick={() => props.previousPageInCarousel()} outline size="lg" data-cy={CyCarousel.PreviousItem}>
                    <FontAwesomeIcon icon={faArrowLeft}/>
                </Button>
                <Button onClick={() => props.nextPageInCarousel()} outline size="lg" data-cy={CyCarousel.NextItem}>
                    <FontAwesomeIcon icon={faArrowRight}/>
                </Button>
            </ButtonGroup>
        </>
    );
}
