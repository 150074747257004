import * as React from 'react';
import {useState} from 'react';
import {CardBody, ListGroup} from 'reactstrap';
import PlaylistSummary from "./PlaylistSummary";
import './PlaylistIndex.css';
import PlaylistData from "../playlist/data/PlaylistData";
import {OrderByButton} from "./OrderByButton";
import {isHotkeyPressed} from "react-hotkeys-hook";
import {MultiChangeToolbar} from "./MultiChangeToolbar";
import {FilterHeader} from "./FilterHeader";

interface Props {
    playlistIndexData: readonly PlaylistData[],
    changeOrderBy: (newField: keyof PlaylistData) => void
    showDetailsInPlaylistSummary: boolean
    orderByAscOrDesc: "asc" | "desc"
    orderByField: keyof PlaylistData
    performSearch: (searchTerm: string) => void
    playlistIndexTableHeaderRef: React.RefObject<HTMLDivElement>
    selectionList: string[]
    setSelectionList: ((value: (((prevState: string[]) => string[]) | string[])) => void)
    currentCrewIdentifier: string | undefined
    setCurrentCrewIdentifier: (i: string | undefined) => void
    performIndexRefresh: () => void
}

export function PlaylistIndexTable(props: Props) {
    const [latestSelectedIdentifier, setLatestSelectedIdentifier] = useState<string | undefined>(undefined);

    function findAllIdentifiersBetweenInclusive(latestSelectedIdentifier: string, identifier: string): readonly string[] {
        let isBetweenSelection = false
        let selection: string[] = []

        props.playlistIndexData.forEach(playlistData => {
            if (playlistData.getIdentifier() === latestSelectedIdentifier || playlistData.getIdentifier() === identifier) {
                selection.push(playlistData.getIdentifier())
                isBetweenSelection = !isBetweenSelection
            }
            if (isBetweenSelection) {
                selection.push(playlistData.getIdentifier())
            }
        })

        return selection
    }

    function getSelectedPlaylists() {
        return props.playlistIndexData.filter(playlistData => props.selectionList.includes(playlistData.getIdentifier()))
    }

    function togglePlaylistSelection(identifier: string) {
        props.setSelectionList((prev: string[]) => {
            if (prev.includes(identifier)) {
                setLatestSelectedIdentifier(undefined)

                return prev.filter(i => i !== identifier)
            } else {
                if (isHotkeyPressed('shift') && latestSelectedIdentifier !== undefined) {
                    const identifiersToAdd = findAllIdentifiersBetweenInclusive(latestSelectedIdentifier, identifier)
                    setLatestSelectedIdentifier(undefined)
                    return [...prev, ...identifiersToAdd]
                } else {
                    setLatestSelectedIdentifier(identifier)
                    return [...prev, identifier]
                }
            }
        })
    }

    function OrderBy(orderByProps: { title: string, field: keyof PlaylistData, dataCy?: string }) {
        return <OrderByButton
            title={orderByProps.title}
            field={orderByProps.field}
            changeOrderBy={props.changeOrderBy}
            currentField={props.orderByField}
            currentOrder={props.orderByAscOrDesc}
            dataCy={orderByProps.dataCy}
        />
    }

    return (
        <>
            <div ref={props.playlistIndexTableHeaderRef}/>
            <CardBody className="sticky-top d-block" style={{backgroundColor: "#f8f9fa"}}>
                <div className=" row d-lg-flex">
                    <div className="col">
                        {/*We don't remove the elment but only hide it to not loose he state when it's not visible.*/}
                        <FilterHeader
                            OrderByBuilder={OrderBy}
                            performSearch={props.performSearch}
                            visible={props.selectionList.length === 0}
                            currentCrewIdentifier={props.currentCrewIdentifier}
                        />
                        {props.selectionList.length > 0 && <MultiChangeToolbar
                            emptySelectionList={() => props.setSelectionList([])}
                            currentCrewIdentifier={props.currentCrewIdentifier}
                            setCurrentCrewIdentifier={props.setCurrentCrewIdentifier}
                            getSelectedPlaylists={getSelectedPlaylists}
                            performIndexRefresh={props.performIndexRefresh}
                        />}
                    </div>
                    <div className="d-none d-lg-flex col-2">
                        <OrderBy title="Erstellt" field="createdAt"/>
                    </div>
                    <div className="d-none d-lg-flex col-2">
                        <OrderBy title="Aktualisiert" field="updatedAt"/>
                    </div>
                </div>
            </CardBody>
            <ListGroup flush>
                {props.playlistIndexData.map(playlistData =>
                    <PlaylistSummary key={playlistData.getIdentifier()}
                                     playlistData={playlistData}
                                     showDetailsInPlaylistSummary={props.showDetailsInPlaylistSummary}
                                     selectionList={props.selectionList}
                                     toggleSelection={togglePlaylistSelection}
                    />
                )}
            </ListGroup>
        </>
    );
}
