import {TocEntryDTO} from "../../dto/v1/toc/TocEntryDTO";
import {TocIdDTO} from "../../dto/v3/toc/TocIdDTO";

class PlaylistItemData {

    public itemId: number;
    public tocId: TocIdDTO;
    public toCEntry?: TocEntryDTO;

    constructor(itemId: number, tocId: TocIdDTO, toCEntry?: TocEntryDTO) {
        this.itemId = itemId;
        this.tocId = tocId;
        this.toCEntry = toCEntry;
    }

}

export default PlaylistItemData;
