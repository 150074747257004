import * as React from 'react';
import {RefObject, useEffect, useState} from 'react';
import {Input} from "reactstrap";
import {useDebounce} from "use-debounce";
import {CyDelayedFilterInput} from "./CyDelayedFilterInput";

interface Props {
    onSearchTermChanged: (searchTerm: string) => void
    autoFocus: boolean
    inputRef: RefObject<HTMLInputElement>
    placeholder : string
}

export function DelayedFilterInput(props: Props) {

    const [searchTerm, setSearchTerm] = useState("");

    const [debouncedSearchTerm] = useDebounce(searchTerm, 500);

    useEffect(() => {
        props.onSearchTermChanged(debouncedSearchTerm)
    }, [debouncedSearchTerm]); // eslint-disable-line react-hooks/exhaustive-deps

    return (
        <Input
            type="search"
            innerRef={props.inputRef}
            placeholder={props.placeholder}
            value={searchTerm}
            autoFocus={props.autoFocus}
            data-cy={CyDelayedFilterInput.InputBoxSearchSong}
               onChange={(ev: React.FormEvent<HTMLInputElement>) => {
                   setSearchTerm(ev.currentTarget.value)
               }}/>
    );
}