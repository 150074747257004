import * as React from 'react';
import {useEffect} from 'react';
import {Button, Modal, ModalBody, ModalFooter, ModalHeader} from 'reactstrap';
import './PlaylistIndex.css';
import PlaylistData from "../playlist/data/PlaylistData";
import {PlaylistIndexManipulator} from "./PlaylistIndexManipulator";
import {CyDeletePlaylistsModal} from "./CyDeletePlaylistsModal";

interface Props {
    isModalOpen: boolean
    setIsModalOpen: ((value: (((prevState: boolean) => boolean) | boolean)) => void)
    performIndexRefresh: () => void
    getSelectedPlaylists: () => readonly PlaylistData[]
}

export function DeletePlaylistsModal(props: Props) {
    const toggle = () => props.setIsModalOpen((v) => !v);

    const [selectedPlaylists, setSelectedPlaylists] = React.useState<readonly PlaylistData[]>(props.getSelectedPlaylists())

    useEffect(() => {
        setSelectedPlaylists(props.getSelectedPlaylists())
    }, [props.isModalOpen]);

    function deleteSelectedPlaylists() {
        PlaylistIndexManipulator.deletePlaylists(selectedPlaylists)
        props.performIndexRefresh()
        props.setIsModalOpen(false)
    }

    return (
        <Modal isOpen={props.isModalOpen} fullscreen="md" toggle={toggle} data-cy={CyDeletePlaylistsModal.DeletePlaylistsModal}>
            <ModalHeader>Playlists löschen</ModalHeader>
            <ModalBody>
                <p>Sollen die ausgewählten Playlists wirklich gelöscht werden?</p>
                <ul>
                    {selectedPlaylists.map(p => <li key={p.getIdentifier()}>{p.title}</li>)}
                </ul>
            </ModalBody>
            <ModalFooter>
                <Button
                    color="secondary"
                    onClick={toggle}
                >
                    Abbrechen
                </Button>{' '}
                <Button
                    color="primary"
                    onClick={() => deleteSelectedPlaylists()}
                    data-cy={CyDeletePlaylistsModal.ButtonConfirmDeletion}
                >
                    Löschen
                </Button>
            </ModalFooter>
        </Modal>
    );
}
