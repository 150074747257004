export default class LocalStorageKey {
    public static readonly LOCAL_STORAGE_KEY_IDENTIFIER_SEPARATOR = "-";

    public readonly localStorageKey = this.buildLocalStorageKey();

    public constructor(protected keyPartOne: string, protected keyPartTwo: string | undefined) {}




    private buildLocalStorageKey(): string {
        return this.keyPartOne + ((this.keyPartTwo !== undefined) ? LocalStorageKey.LOCAL_STORAGE_KEY_IDENTIFIER_SEPARATOR + this.keyPartTwo : "");
    }

}