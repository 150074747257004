import React from 'react';
import {ListGroupItem} from "reactstrap";
import PlaylistData from "../data/PlaylistData";
import PlaylistItemData from "../data/PlaylistItemData";
import PlaylistItemSong from "../item/PlaylistItemSong";
import PlaylistItemInvalid from "../item/PlaylistItemInvalid";

interface Props {
    playlistData: PlaylistData
    selectedItem: PlaylistItemData | undefined
    itemClickedHandler: (item: PlaylistItemData) => void
    showImageThumbnails: boolean
}

export default function Entries(props: Props) {

    return (
        <>
            {props.playlistData.getItems().map((entry) => {
                if (entry.toCEntry) {
                    return (
                        <ListGroupItem tag="button" color="link" action onClick={() => props.itemClickedHandler(entry)} key={"lgi" + entry.itemId}
                                       active={entry.itemId === props.selectedItem?.itemId}>
                            <PlaylistItemSong
                                {...props}
                                playlistEntry={entry}
                                showImageThumbnails={props.showImageThumbnails}
                            />

                        </ListGroupItem>
                    )
                } else {
                    return (
                        <ListGroupItem key={"lgi" + entry.itemId}>
                            <PlaylistItemInvalid {...props} playlistEntry={entry}/>
                        </ListGroupItem>
                    )
                }
            })}
        </>
    );
}