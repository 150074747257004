import LocalStoragePersistence from "../localStoragePersistence/common/LocalStoragePersistence";
import LocalStorageKey from "../localStoragePersistence/common/LocalStorageKey";

/**
 * All data (songs, playlists etc.) from a logged in user are saved on local storage to be able to work offline.
 * If now a different user logs in, we first need to empty the local storage.
 * This class helps to detect if local storage data has to be deleted.
 * The reason is to avoid a data chaos: A new user would e. g. sync playlists from the user before.
 *
 * This mechanism has nothing to do with security. The data is on the local storage an can easily be stolen by someone else.
 */
export class LoginByDifferentUserHandler {

    public static readonly AUTH0_SUBJECT_OF_USER_OWNING_CURRENT_LOCAL_STORAGE_DATA_LOCAL_STORAGE_KEY = new LocalStorageKey("LoginByDifferentUserHandler", "auth0SubjectOfUserOwningCurrentLocalStorageData")

    static isMessageToUserNecessaryThatDeletionOfLocalStorageDataIsNeededToLogin(auth0SubjectNewlyLoggedInUser: string): boolean {
        const auth0SubjectOfUserOwningCurrentLocalStorageData = LocalStoragePersistence.read(LoginByDifferentUserHandler.AUTH0_SUBJECT_OF_USER_OWNING_CURRENT_LOCAL_STORAGE_DATA_LOCAL_STORAGE_KEY, undefined)

        if (auth0SubjectOfUserOwningCurrentLocalStorageData === undefined) {
            //user logs in for the first time
            console.info("Could not find an auth0 subject of a previously logged in user.");
            return false
        } else if (auth0SubjectNewlyLoggedInUser === auth0SubjectOfUserOwningCurrentLocalStorageData) {
            return false
        } else {
            console.info(`Auth0 subject of newly logged in user and the user owning the data currently stored on local stoarge do not match --> Deletion necessary. auth0SubjectNewlyLoggedInUser=${auth0SubjectNewlyLoggedInUser}, auth0SubjectOfUserOwningCurrentLocalStorageData${auth0SubjectOfUserOwningCurrentLocalStorageData}`);
            return true
        }
    }

    static persistCurrentAuth0Subject(auth0Subject: String) {
        LocalStoragePersistence.persist(LoginByDifferentUserHandler.AUTH0_SUBJECT_OF_USER_OWNING_CURRENT_LOCAL_STORAGE_DATA_LOCAL_STORAGE_KEY, auth0Subject);
    }
}
