import * as React from 'react';
import './SongsheetCarousel.css';
import PlaylistItemData from "../playlist/data/PlaylistItemData";
import Entries from "../playlist/overview/Entries";
import PlaylistData from "../playlist/data/PlaylistData";
import {Card, ListGroup} from "reactstrap";
import {CardHeaderWithCloseButton} from "../common/CardHeaderWithCloseButton";

interface Props {
    playlistData: PlaylistData
    selectedItem: PlaylistItemData | undefined
    setSelectedItem: (item: PlaylistItemData | undefined) => void
    closeSidebar: () => void
    isBigScreen: boolean
}

export default function CarouselPlaylistSidebar(props: Props) {

    function itemClickedHandler(item: PlaylistItemData) {
        props.setSelectedItem(item);
        !props.isBigScreen && props.closeSidebar();
    }

    return (
        <Card>
            <CardHeaderWithCloseButton title="Playlist" onCloseFunction={props.closeSidebar}/>
            <ListGroup flush>
                <Entries
                    playlistData={props.playlistData}
                    selectedItem={props.selectedItem}
                    itemClickedHandler={itemClickedHandler}
                    showImageThumbnails={false}
                />
            </ListGroup>
        </Card>
    );
}

