import * as React from 'react';
import {useState} from 'react';
import {Button, Col, Row} from 'reactstrap';
import './PlaylistIndex.css';
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faClone, faTrashAlt, faXmark} from "@fortawesome/free-solid-svg-icons";
import {CopyMovePlaylistsModal} from "./CopyMovePlaylistsModal";
import PlaylistData from "../playlist/data/PlaylistData";
import {DeletePlaylistsModal} from "./DeletePlaylistsModal";
import {CyMultiChangeToolbar} from "./CyMultiChangeToolbar";

interface Props {
    emptySelectionList: () => void
    currentCrewIdentifier: string | undefined
    setCurrentCrewIdentifier: (i: string | undefined) => void
    getSelectedPlaylists: () => readonly PlaylistData[]
    performIndexRefresh: () => void
}

export function MultiChangeToolbar(props: Props) {
    const [isCopyMoveModalOpen, setIsCopyMoveModalOpen] = useState<boolean>(false);

    const [isDeleteModalOpen, setIsDeleteModalOpen] = useState<boolean>(false);

    return (
        <>
            <CopyMovePlaylistsModal
                currentCrewIdentifier={props.currentCrewIdentifier}
                isModalOpen={isCopyMoveModalOpen}
                setIsModalOpen={setIsCopyMoveModalOpen}
                getSelectedPlaylists={props.getSelectedPlaylists}
                setCurrentCrewIdentifier={props.setCurrentCrewIdentifier}
                performIndexRefresh={props.performIndexRefresh}
            />
            <DeletePlaylistsModal
                isModalOpen={isDeleteModalOpen}
                setIsModalOpen={setIsDeleteModalOpen}
                getSelectedPlaylists={props.getSelectedPlaylists}
                performIndexRefresh={props.performIndexRefresh}
            />
            <Row className={"mb-0 g-2 row-cols-auto "} data-cy={CyMultiChangeToolbar.Toolbar}>
                <Col>
                    <Button
                        color="primary"
                        onClick={props.emptySelectionList}
                        data-cy={CyMultiChangeToolbar.EmptySelection}
                    >
                        <FontAwesomeIcon icon={faXmark}/> Selektion löschen
                    </Button>
                </Col>
                <Col>
                    <Button
                        color="primary"
                        onClick={() => setIsDeleteModalOpen(true)}
                        data-cy={CyMultiChangeToolbar.DeletePlaylists}
                    >
                        <FontAwesomeIcon icon={faTrashAlt}/> Löschen
                    </Button>
                </Col>
                <Col>
                    <Button
                        color="primary"
                            onClick={() => setIsCopyMoveModalOpen(true)}
                        data-cy={CyMultiChangeToolbar.CopyMovePlaylists}
                    >
                        <FontAwesomeIcon icon={faClone}/> Verschieben / Kopieren
                    </Button>
                </Col>
            </Row>
        </>
    );
}
