import * as React from 'react';
import {ReactNode} from 'react';
import './appinitandstate/TocAvailabilityNotice.css';
import {Route, Router, Switch} from "react-router-dom";
import TocAvailabilityNotice from "./appinitandstate/TocAvailabilityNotice";
import AuthImpl from "./auth/AuthImpl";
import history from './history';
import Auth from "./auth/Auth";
import {TocAvailability} from "./appinitandstate/TocAvailability";
import {BackendServerAvailability} from "./appinitandstate/BackendServerAvailability";
import {TableOfContentsDTO} from "./dto/v1/toc/TableOfContentsDTO";
import {SearchEngine} from "./search/SearchEngine";
import {TocEntryDTO} from "./dto/v1/toc/TocEntryDTO";
import Settings from "./Settings/Settings";
import {UpdateWorker} from "./offline/UpdateWorker";
import {OfflineUpdateSummary} from "./offline/OfflineUpdateSummary";
import Home from "./Home";
import PlaylistPreparer from "./playlist/PlaylistPreparer";
import {AuthState} from "./appinitandstate/AuthState";
import Playlist from "./playlist/Playlist";
import SharePlaylist from "./playlist/share/SharePlaylist";
import {RoutePaths} from "./RoutePaths";
import PlaylistUrlImportDialog from "./playlist/import/url/PlaylistUrlImportDialog";
import {AppState} from "./appinitandstate/AppState";
import {AppInitializer} from "./appinitandstate/AppInitializer";
import {TocIdDTO} from "./dto/v3/toc/TocIdDTO";
import {LocalStorageDeletionNecessaryNotice} from "./appinitandstate/LocalStorageDeletionNecessaryNotice";
import {AuthFailedNotice} from "./appinitandstate/AuthFailedNotice";
import {StubLogin} from "./auth/stub/StubLogin";
import {JoinCrewDialog} from "./crew/JoinCrewDialog";
import {DownloadNecessityChecker} from "./offline/DownloadNecessityChecker";
import {AuthAlwaysAuthorizedStub} from "./auth/AuthAlwaysAuthorizedStub";
import Header from "./Header";
import {LoadingMessage} from "./LoadingMessage";
import {createClient, fetchExchange} from '@urql/core';
import {offlineExchange} from '@urql/exchange-graphcache';
import {devtoolsExchange} from '@urql/devtools';
import {Client, Provider} from "urql";
import {AuthConfig, BackendConfig} from "./Config";
import {makeDefaultStorage} from "@urql/exchange-graphcache/default-storage";
import schema from "./gql/graphql.schema.json"

interface State extends AppState {
    toc?: TableOfContentsDTO,
    searchEngine: SearchEngine,
    offlineUpdateSummary?: OfflineUpdateSummary,
    downloadOfFilesNecessary: boolean
}

const storage = makeDefaultStorage({
    idbName: 'songship-v1', // The name of the IndexedDB database
    maxAge: 366, // The maximum age of the persisted data in days
});

const cache = offlineExchange({
    storage,
    schema,
    keys: {
        // @ts-ignore
        CrewInformationUB: data => data.identifier,
        PlaylistItemUB: data => null,
        TocIdUB: data => null,
        // @ts-ignore
        PlaylistUB: data => data.identifier,
    },
});

export const bigScreenThreshold = "992px";

class App extends React.Component<{}, State> {

    private TEST_SETUP_ROUTE = "testSetup";

    private readonly auth: Auth;
    private readonly updateWorker: UpdateWorker;

    constructor(props: Readonly<{}>) {
        super(props);

        this.lookupSong = this.lookupSong.bind(this);
        this.updateToc = this.updateToc.bind(this);
        this.notifyProgress = this.notifyProgress.bind(this);
        this.resetProgress = this.resetProgress.bind(this);
        this.startUpdate = this.startUpdate.bind(this);

        if (process.env.NODE_ENV === "production" || AuthConfig.useProdSecurityInDevMode) {
            this.auth = new AuthImpl();
        } else {
            this.auth = new AuthAlwaysAuthorizedStub();
        }
        this.updateWorker = new UpdateWorker(this.auth, this.notifyProgress);

        this.state = {
            tocAvailability: TocAvailability.Undecided,
            backendServerAvailability: BackendServerAvailability.Undecided,
            authState: AuthState.Undecided,
            downloadOfFilesNecessary: false,
            searchEngine: new SearchEngine([]),
        };
    }

    //This is just the creation of the client. It will not be usable if we have no internet connection or no valid auth token
    private readonly graphQlClient: Client = createClient({
        url: BackendConfig.baseUrl + '/graphql',
        fetchOptions: () => {
            const token = this.auth.getAccessToken();
            return {
                headers: {authorization: token ? `Bearer ${token}` : ''},
            };
        },
        requestPolicy: 'network-only', //the idea is to overwrite this per request
        exchanges: [cache, devtoolsExchange, fetchExchange],
    });

    public componentDidMount() {
        if (window.location.pathname.includes(this.TEST_SETUP_ROUTE)) {
            console.info("Skipping all app initialization. This route allows you to set some local storage values for testing.");
            return;
        }

        const initializer = new AppInitializer(this.auth, this.graphQlClient, this);
        initializer.initApp()
            .then((tocOrState: TableOfContentsDTO | TocAvailability) => {
                if (tocOrState instanceof TableOfContentsDTO) {
                    return this.updateToc(tocOrState);
                } else {
                    return this.updateTocAvailability(tocOrState);
                }
            })
            .then(() => {
                console.info("App initialization finished successfully.")
            })
            .catch(e => {
                console.error("App initialization failed.", e)
            })
            //The following won't return a promise!
            .then(() => this.checkIfUpdateIsNecessary())
    }

    private notifyProgress(summary: OfflineUpdateSummary) {
        this.setState((prevState, props) => ({offlineUpdateSummary: summary}));
    }

    private resetProgress() {
        this.setState((prevState, props) => ({
            offlineUpdateSummary: undefined,
            downloadOfFilesNecessary: false
        }), () => this.checkIfUpdateIsNecessary());
    }

    private checkIfUpdateIsNecessary(): void {
        if (this.state.toc instanceof TableOfContentsDTO) {
            console.debug("Checking, if offline data update is necessary...");
            new DownloadNecessityChecker()
                .isFileDownloadNecessary(this.state.toc)
                .then(isUpdateNecessary => this.setState((prevState, props) => ({downloadOfFilesNecessary: isUpdateNecessary})));
        } else {
            console.debug("Skipping offline data check because toc is", this.state.toc);
        }
    }

    private updateToc(t: TableOfContentsDTO): Promise<void> {
        return new Promise<void>(resolve => {
            this.setState(() => ({
                tocAvailability: TocAvailability.ToCIsAvailable,
                toc: t,
                searchEngine: new SearchEngine(Array.from(t.values()))
            }), () => resolve());
        });
    }

    private updateTocAvailability(a: TocAvailability): Promise<void> {
        return new Promise<void>(resolve => {
            this.setState(() => ({
                tocAvailability: a
            }), () => resolve());
        })
    }

    private lookupSong(id: TocIdDTO): TocEntryDTO | undefined {
        return (this.state.toc) ? this.state.toc.get(id) : undefined
    }

    public render() {
        return (
            <Router history={history}>
                <Switch>
                    <Route path={`/${RoutePaths.CALLBACK}`} render={(props) => {
                        return this.loading("Die Antwort vom Authentifizierungsserver wird verarbeitet.")
                    }}/>
                    <Route path={`/${this.TEST_SETUP_ROUTE}`} render={(props) => {
                        return this.loading("Skipping all app initialization. This route allows you to set some local storage values for testing.");
                    }}/>
                    <Route path={`/${RoutePaths.STUB_LOGIN}`} render={(props) => {
                        return (<StubLogin/>)
                    }}/>
                    {this.logoutNode()}
                    {(this.state.backendServerAvailability === BackendServerAvailability.Undecided || this.state.authState === AuthState.Undecided) ? (
                        <Route path="/" exact render={(props) => {
                            return this.loading("Die Online-Verbindung und deine Identität werden überprüft.")
                        }}/>
                    ) : (this.state.authState === AuthState.LocalStorageDeletionNecessary) ? (
                        <LocalStorageDeletionNecessaryNotice auth={this.auth}/>
                    ) : (this.state.authState === AuthState.Failed) ? (
                        <AuthFailedNotice logout={this.auth.logout}/>
                    ) : (this.state.tocAvailability === TocAvailability.ToCIsAvailable) ? (
                        <Provider value={this.graphQlClient}>
                            <Switch>
                                <Route path={`/${RoutePaths.PLAYLIST}/:identifier`} render={(props) => {
                                    return (
                                        <PlaylistPreparer identifier={props.match.params.identifier}
                                                          nodeToRender={this.playlistNode(props.match.params.identifier)}/>
                                    );
                                }}/>
                                <Route path={`/${RoutePaths.SHARE_PLAYLIST}/:identifier`} render={(props) => {
                                    return (
                                        <PlaylistPreparer identifier={props.match.params.identifier}
                                                          nodeToRender={this.sharePlaylistNode(props.match.params.identifier)}/>
                                    );
                                }}/>
                                <Route path={`/${RoutePaths.IMPORT_PLAYLIST}/:identifier`} render={(props) => {
                                    return (
                                        <PlaylistUrlImportDialog importIdentifier={props.match.params.identifier}
                                                                 lookupSong={this.lookupSong}/>
                                    );
                                }}/>
                                <Route path={`/${RoutePaths.JOIN_CREW}/:joinIdentifier`} render={(props) => {
                                    return (
                                        <JoinCrewDialog
                                            joinIdentifier={props.match.params.joinIdentifier}
                                            emailResolver={this.auth.getEmail}
                                        />
                                    );
                                }}/>
                                <Route path={`/${RoutePaths.SETTINGS}`} render={(props) => {
                                    return (<Settings auth={this.auth} startUpdate={this.startUpdate}/>);
                                }}/>
                                <Route path="/" render={(props) => {
                                    return (<Home auth={this.auth}
                                                  tocAvailability={this.state.tocAvailability}
                                                  backendServerAvailability={this.state.backendServerAvailability}
                                                  startUpdate={this.startUpdate}
                                                  resetProgress={this.resetProgress}
                                                  lookupSong={this.lookupSong}
                                                  offlineUpdateSummary={this.state.offlineUpdateSummary}
                                                  downloadOfFilesNecessary={this.state.downloadOfFilesNecessary}
                                    />);
                                }}/>
                            </Switch>
                        </Provider>
                    ) : (
                        <TocAvailabilityNotice logout={this.auth.logout}
                                               tocAvailability={this.state.tocAvailability}/>
                    )}
                </Switch>
            </Router>
        );
    }

    private startUpdate() {
        this.state.toc && this.updateWorker.update(this.state.toc);
    }

    private playlistNode(identifier: string): ReactNode {
        return (<Playlist playlistIdentifier={identifier} lookupSong={this.lookupSong}
                          searchEngine={this.state.searchEngine} auth={this.auth}/>)
    }

    private sharePlaylistNode(identifier: string): ReactNode {
        return (<SharePlaylist identifier={identifier} lookupSong={this.lookupSong}/>)
    }

    private logoutNode(): ReactNode {
        return (<Route path={`/${RoutePaths.LOGOUT}`} render={(props) => {
            this.auth.logout();
            return this.loading("Du wirst ausgelogt...")
        }}/>);
    }

    private loading(message: string): ReactNode {
        return (<>
            <Header/>
            <br/>
            <LoadingMessage message={message} showGoToRootPageAfterMs={4000}/>
        </>)
    }
}

export default App;
