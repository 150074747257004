import * as React from 'react';
import {useEffect, useState} from 'react';
import {useStateWithLocalStoragePersistenceAndDefault} from "../common/hooks/useStateWithLocalStoragePersistenceAndDefault";
import {BeamerBroadcastChannelReceiver} from "./BeamerBroadcastChannelReceiver";
import {CyBeamer} from "./CyBeamer";
import {BeamerBroadcastChannel} from "./BeamerBroadcastChannel";
import LocalStorageKey from "../localStoragePersistence/common/LocalStorageKey";


export function SongsheetBeamerView() {

    const [fontSizeInViewHeight, setFontSizeInViewHeight] = useStateWithLocalStoragePersistenceAndDefault<number>(new LocalStorageKey("SongsheetBeamerView", "fontSizeInViewHeight"), 8);

    const [content, setContent] = useState<string>(BeamerBroadcastChannel.BeamerViewDefaultContent);

    const increaseFontSize = () => setFontSizeInViewHeight(prev => Math.min(16, prev + 1))
    const decreaseFontSize = () => setFontSizeInViewHeight(prev => Math.max(2, prev - 1))

    useEffect(() => {
        new BeamerBroadcastChannelReceiver(setContent, increaseFontSize, decreaseFontSize);
    }, []); // eslint-disable-line react-hooks/exhaustive-deps

    return (
        <div data-cy={CyBeamer.LyricsContent}
             style={{backgroundColor: "black", fontSize: fontSizeInViewHeight + "vh", color: "whitesmoke", height: "100vh", padding: "1vw", whiteSpace: "pre-line"}}>
            {content}
        </div>
    );
}