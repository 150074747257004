import {BroadcastChannel} from "broadcast-channel";
import {BeamerBroadcastChannel} from "./BeamerBroadcastChannel";

export class BeamerBroadcastChannelReceiver {
    readonly channel: BroadcastChannel;

    constructor(
        setContent: (content: string) => void,
        increaseFontSize: () => void,
        decreaseFontSize: () => void
    ) {
        this.channel = new BroadcastChannel(BeamerBroadcastChannel.ChannelName);

        this.channel.onmessage = function (this: BroadcastChannel, ev: string) {
            if (ev === BeamerBroadcastChannel.IncreaseFontSize) {
                increaseFontSize();
            } else if (ev === BeamerBroadcastChannel.DecreaseFontSize) {
                decreaseFontSize();
            } else {
                setContent(ev);
            }
        }
    }
}
