import * as React from 'react';
import {useEffect, useRef, useState} from 'react';
import {Badge} from "reactstrap";
import PlaylistData from "../playlist/data/PlaylistData";
import PlaylistItemData from "../playlist/data/PlaylistItemData";
import ArrayUtils from "../common/ArrayUtils";

interface Props {
    playlistData: PlaylistData;
    selectedItem: PlaylistItemData | undefined;
}

// When a new item is selected, we scroll to the item before and then to the one after.
// As long as the width of the display is big enough to show three entries, this ensures that we always will be able to see the entry before and after.
export default function PlaylistBanner(props: Props) {

    const selectedItemBeforeBatchRef: React.MutableRefObject<HTMLElement | null> = useRef(null);
    const selectedItemAfterBatchRef: React.MutableRefObject<HTMLElement | null> = useRef(null);

    const [itemToScrollToAfterSelectedItem, setItemToScrollToAfterSelectedItem] = useState<PlaylistItemData | undefined>(undefined);
    const [itemToScrollToBeforeSelectedItem, setItemToScrollToBeforeSelectedItem] = useState<PlaylistItemData | undefined>(undefined);

    useEffect(() => {
        const previousItem = ArrayUtils.previousOf<PlaylistItemData>(props.playlistData.getItems(), i => i.itemId === props.selectedItem?.itemId)
        setItemToScrollToBeforeSelectedItem(previousItem ? previousItem : undefined);
        const followingItem = ArrayUtils.followingOf<PlaylistItemData>(props.playlistData.getItems(), i => i.itemId === props.selectedItem?.itemId)
        setItemToScrollToAfterSelectedItem(followingItem ? followingItem : undefined);
    }, [props.selectedItem]); // eslint-disable-line react-hooks/exhaustive-deps


    useEffect(() => {
        selectedItemBeforeBatchRef.current?.scrollIntoView(true);
        selectedItemAfterBatchRef.current?.scrollIntoView(true);
    }, [itemToScrollToAfterSelectedItem, itemToScrollToBeforeSelectedItem]);

    return (
        <div style={{width: "100%", overflowX: "hidden", whiteSpace: "nowrap", flexShrink: 0, backgroundColor: "rgb(248, 249, 250)"}} className="text-center">
            {props.playlistData.getItems().map(playlistEntry => {
                const active = playlistEntry.itemId === props.selectedItem?.itemId;
                const isItemToScrollToBeforeSelectedItem = playlistEntry.itemId === itemToScrollToBeforeSelectedItem?.itemId;
                const isItemToScrollToAfterSelectedItem = playlistEntry.itemId === itemToScrollToAfterSelectedItem?.itemId;
                return <Badge pill className="me-1" color={active ? "warning" : "secondary"}
                              innerRef={el => {
                                  if (isItemToScrollToBeforeSelectedItem) selectedItemBeforeBatchRef.current = el;
                                  if (isItemToScrollToAfterSelectedItem) selectedItemAfterBatchRef.current = el;
                              }}
                              key={playlistEntry.itemId}>
                    {playlistEntry.toCEntry ? playlistEntry.toCEntry.title : "Ungültiger Eintrag"}
                </Badge>

            })}
        </div>

    );

}