import PlaylistItemData from "../../playlist/data/PlaylistItemData";
import _ from "lodash";
import {VirtualSlide} from "./VirtualSlide";
import {VirtualInvalidSlide} from "./VirtualInvalidSlide";
import {VirtualTextOnlySlide} from "./VirtualTextOnlySlide";
import {VirtualSongsheetSlide} from "./VirtualSongsheetSlide";
import {VirtualLyricsSlide} from "./VirtualLyricsSlide";


export class VirtualSlideFactory {

    public static buildSongsheetSlides(items: ReadonlyArray<PlaylistItemData>): VirtualSlide[] {
        return _.flatten(items.map(item => this.buildSongsheetSlidesForItem(item)))
    }

    static buildSongsheetSlidesForItem(item: PlaylistItemData): VirtualSlide[] {
        if (item.toCEntry === undefined) {
            return [new VirtualInvalidSlide(item)]
        } else if (item.toCEntry.pictureReferences.length === 0) {
            return [new VirtualTextOnlySlide(item)]
        } else {
            return item.toCEntry.pictureReferences.map(r => new VirtualSongsheetSlide(item, r))
        }
    }

    public static buildLyricsSlides(items: ReadonlyArray<PlaylistItemData>): VirtualSlide[] {
        return items.map(item => this.buildLyricsSlide(item))
    }

    static buildLyricsSlide(item: PlaylistItemData): VirtualSlide {
        if (item.toCEntry === undefined) {
            return new VirtualInvalidSlide(item)
        } else {
            return new VirtualLyricsSlide(item, item.toCEntry)
        }
    }
}
