import * as React from 'react';
import {useState} from 'react';
import {Button, Modal, ModalBody, ModalFooter, ModalHeader} from 'reactstrap';
import './PlaylistIndex.css';
import {renderLoadingUntilDefined} from "../common/LoadingUntilDefined";
import {CrewInformationUb} from "../gql/graphql";
import {useCrewQuery} from "../crew/useCrewQuery";
import PlaylistData from "../playlist/data/PlaylistData";
import {PlaylistIndexManipulator} from "./PlaylistIndexManipulator";
import {CrewSelector} from "../crew/CrewSelector";
import {CyCopyMovePlaylistsModal} from "./CyCopyMovePlaylistsModal";

interface Props {
    currentCrewIdentifier: string | undefined
    setCurrentCrewIdentifier: (i: string | undefined) => void
    isModalOpen: boolean
    setIsModalOpen: ((value: (((prevState: boolean) => boolean) | boolean)) => void)
    performIndexRefresh: () => void
    getSelectedPlaylists: () => readonly PlaylistData[]
}

export function CopyMovePlaylistsModal(props: Props) {
    const crewInformationMaybe: ReadonlyArray<CrewInformationUb> | undefined = useCrewQuery()

    const [targetCrewIdentifier, setTargetCrewIdentifier] = useState<string | undefined>(props.currentCrewIdentifier);

    const toggle = () => props.setIsModalOpen((v) => !v);

    function copySelectedPlaylistsToCrew() {
        PlaylistIndexManipulator.copyPlaylistsToCrew(props.getSelectedPlaylists(), targetCrewIdentifier)
        props.setCurrentCrewIdentifier(targetCrewIdentifier)
        props.performIndexRefresh()
        props.setIsModalOpen(false)
    }

    function moveSelectedPlaylistsToCrew() {
        PlaylistIndexManipulator.movePlaylistsToCrew(props.getSelectedPlaylists(), targetCrewIdentifier)
        props.setCurrentCrewIdentifier(targetCrewIdentifier)
        props.setIsModalOpen(false)
    }

    return (
        <Modal isOpen={props.isModalOpen} fullscreen="md" toggle={toggle} data-cy={CyCopyMovePlaylistsModal.CopyMovePlaylistsModal}>
            <ModalHeader>Verschieben / Kopieren</ModalHeader>
            <ModalBody>
                {renderLoadingUntilDefined(crewInformationMaybe, (crewInformation) => {
                    return <CrewSelector
                        crewInformation={crewInformation}
                        targetCrewIdentifier={targetCrewIdentifier}
                        setTargetCrewIdentifier={setTargetCrewIdentifier}
                        size="small"
                    />
                })}

            </ModalBody>
            <ModalFooter>
                <Button
                    color="secondary"
                    onClick={toggle}
                >
                    Abbrechen
                </Button>{' '}
                <Button
                    color="primary"
                    onClick={() => moveSelectedPlaylistsToCrew()}
                    data-cy={CyCopyMovePlaylistsModal.ButtonMove}
                >
                    Verschieben
                </Button>{' '}
                <Button
                    color="primary"
                    onClick={() => copySelectedPlaylistsToCrew()}
                    data-cy={CyCopyMovePlaylistsModal.ButtonCopy}
                >
                    Kopieren
                </Button>
            </ModalFooter>
        </Modal>
    );
}
