import React from 'react';
import {Alert, Button, Card, CardBody, CardHeader, ListGroup, ListGroupItem, Nav, Navbar, NavItem} from "reactstrap";
import NavItemTogglePersistent from "../common/NavItemTogglePersistent";
import {Link, useHistory} from "react-router-dom";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faHome, faShareAlt} from "@fortawesome/free-solid-svg-icons";
import PlaylistFooterMenu from "./PlaylistFooterMenu";
import PlaylistData from "./data/PlaylistData";
import PlaylistMenu from "./PlaylistMenu";
import useToggleValueWithLocalStoragePersistence from "../common/hooks/useToggleValueWithLocalStoragePersistence";
import {PlaylistRoutePaths} from "./PlaylistRoutePaths";
import PlaylistItemData from "./data/PlaylistItemData";
import Header from "../Header";
import {QrCodeScannedPlaylistInfo} from "./QrCodeScannedPlaylistInfo";
import {PushNeededInfo} from "./PushNeededInfo";
import {CyPlaylist} from "./CyPlaylist";
import {RenderPermissionBased} from "../auth/RenderPermissionBased";
import Auth from "../auth/Auth";
import {Permission} from "../auth/Permission";
import Entries from "./overview/Entries";
import {TitleEditable} from "./TitleEditable";
import LocalStorageKey from "../localStoragePersistence/common/LocalStorageKey";
import {RoutePaths} from "../RoutePaths";

interface Props {
    playlistData: PlaylistData
    setTitle: (title: string) => void
    moveUp: (itemId: number) => void;
    moveDown: (itemId: number) => void;
    remove: (itemId: number) => void;
    hasInvalidItems: boolean;
    removeInvalidItems: () => void;
    selectedItem: PlaylistItemData | undefined;
    setSelectedItem: (item: PlaylistItemData | undefined) => void;
    auth: Auth;
}

export default function PlaylistOverview(props: Props) {
    const [showImageThumbnails, setShowImageThumbnails] = useToggleValueWithLocalStoragePersistence(new LocalStorageKey("Playlist", "showImageThumbnails"), false);

    const history = useHistory();

    function itemClickedHandler(item: PlaylistItemData) {
        if (item.itemId === props.selectedItem?.itemId)
            history.push(PlaylistRoutePaths.toCarousel(props.playlistData.getIdentifier()));
        else
            props.setSelectedItem(item);
    }

    return (
        <>
            <Header/>
            <Navbar color="light" className="navbar-expand" light>
                <Nav navbar>
                    <NavItem>
                        <Button tag={Link} className="nav-link" to="/" color="link"><FontAwesomeIcon icon={faHome}/> Home</Button>
                    </NavItem>
                </Nav>
            </Navbar>
            <br/>
            <Card>
                <CardHeader className="pb-0" style={{backgroundColor: "#f8f9fa", borderBottom: "none"}}>
                    <h2 className={"light-header"}>
                        <TitleEditable content={props.playlistData.title} updateContent={props.setTitle} editAllowed={!props.playlistData.isQrCodeScannedPlaylist}/>
                    </h2>
                    <Navbar light expand="lg">
                        <Nav className="me-auto" navbar>
                            <RenderPermissionBased auth={props.auth} requiredPermissions={[Permission.songsheetsRead]}>
                                <NavItemTogglePersistent value="Vorschaubilder anzeigen" setIsActive={setShowImageThumbnails} isActive={showImageThumbnails}/>
                            </RenderPermissionBased>
                            <NavItem>
                                <Link className="nav-link" to={`/${RoutePaths.SHARE_PLAYLIST}/` + props.playlistData.getIdentifier()}><FontAwesomeIcon icon={faShareAlt}/> Teilen</Link>
                            </NavItem>
                        </Nav>
                    </Navbar>
                </CardHeader>
                <CardHeader className="sticky-top" style={{backgroundColor: "#f8f9fa"}}>
                    {!props.playlistData.isQrCodeScannedPlaylist &&
                        <PlaylistMenu
                            {...props}
                            playlistIdentifier={props.playlistData.getIdentifier()}
                            selectedItemId={props.selectedItem?.itemId}
                        />}
                </CardHeader>
                <CardBody>
                    <ListGroup flush>
                        {!props.playlistData.isQrCodeScannedPlaylist && props.hasInvalidItems && <ListGroupItem>
                            <Alert color="warning" data-cy={CyPlaylist.AlertUnknownSongs}>
                                <p>Unbekannte Lieder</p>
                                <p>Diese Playlist enthält unbekannte Lieder. Willst du sie bereinigen und alle unbekannten Lieder entfernen?</p>
                                <p>
                                    <Button onClick={() => props.removeInvalidItems()} color="warning">Bereinigen</Button>
                                </p>
                            </Alert>
                        </ListGroupItem>
                        }
                        {props.playlistData.isQrCodeScannedPlaylist && <QrCodeScannedPlaylistInfo playlistData={props.playlistData}/>}
                        {props.playlistData.needsPush && <PushNeededInfo/>}
                        <Entries
                            playlistData={props.playlistData}
                            selectedItem={props.selectedItem}
                            itemClickedHandler={itemClickedHandler}
                            showImageThumbnails={showImageThumbnails}
                        />

                        {!props.playlistData.isQrCodeScannedPlaylist &&
                            <ListGroupItem className="footer">
                                <PlaylistFooterMenu
                                    {...props}
                                    playlistIdentifier={props.playlistData.getIdentifier()}
                                />
                            </ListGroupItem>
                        }
                    </ListGroup>
                </CardBody>
            </Card>
        </>
    );
}
